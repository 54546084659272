<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Еженедельные предложения</div>
                <div class="page__desc">Список акций</div>
            </div>
        </div>

        <div class="page__content">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>Неделя</th>
                        <th>Действие</th>
                    </tr>
                    <tr v-for="promotion in weeklyDeals"
                        v-bind:key="promotion.time_from">
                        <td>
                            {{ promotion.time_from | moment("DD MMM YYYY HH:mm") }} – {{ promotion.time_to | moment("DD MMM YYYY HH:mm") }}
                        </td>
                        <td>
                            <router-link
                                    v-if="promotion.id !== undefined"
                                    :to="{ name: 'CatalogPromotionsUpdate', params: {id: promotion.id } }"
                                    tag="input"
                                    type="button"
                                    class="btn btn-sm btn-warning"
                                    value="Изменить"/>
                            <input
                                    v-else
                                    @click="create(promotion)"
                                    type="button"
                                    class="btn btn-sm btn-success"
                                    value="Добавить"/>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import router from '@/router'

    export default {
        name: 'CatalogWeeklyDeals',
        computed: {
            weeklyDeals() {
                return this.$store.state.weeklyDeals.weeklyDeals
            }
        },
        methods: {
            ...mapActions([
                'catalogWeeklyDeals'
            ]),
            async getWeeklyDeals() {
                await this.catalogWeeklyDeals()
            },
            create(promotion) {
                router.push({
                    name: 'CatalogPromotionsCreate',
                    query: {
                        is_weekly: 1,
                        is_visible: 0,
                        time_from: this.$moment(promotion.time_from * 1000).format('YYYY-MM-DDTHH:mm'),
                        time_to: this.$moment(promotion.time_to * 1000).format('YYYY-MM-DDTHH:mm'),
                        discount: 15,
                        title: 'Еженедельные скидки ' + this.$moment(promotion.time_from * 1000).format('DD MMM YYYY')
                    }
                })
            }
        },
        created() {
            this.getWeeklyDeals();
        }
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>